/* Font-Colors-Start */
$neutralBlack-100: rgba(0, 0, 0, 1);
$neutralBlack-75: rgba(0, 0, 0, 0.75);
$neutralBlack-50: rgba(0, 0, 0, 0.5);
$neutralBlack-25: rgba(0, 0, 0, 0.25);
$neutralBlack-2: rgba(34, 39, 43, 1);
$neutralBlack-10: rgba(44, 49, 53, 1);
$neutralBlack-20: rgba(51, 57, 62, 1);
$blogpagecard: rgba(33, 36, 39, 1);
$blog-quote: rgba(42, 48, 53, 1);
$secondary-text-color: rgba(153, 153, 153, 1);
$transparent: transparent;
$form-border: rgba(37, 41, 44, 1);

$card-color: rgba(33, 36, 39, 1);
$badge-color: rgba(42, 48, 53, 1);
$card-header: rgba(32, 37, 40, 1);
$card-body-color: rgba(38, 42, 46, 1);

$badge-font-color: #ffffff80;

$avatarBackground: #E6E6E9;


/* Font-Colors-end */

$body-background: rgba(29, 32, 34, 1);
$text-white: rgba(255, 255, 255, 1);
$text-white-90: rgba(255, 255, 255, 0.9);
$text-white-25: rgba(255, 255, 255, 0.25);
$text-white-75: rgba(255, 255, 255, 0.75);
$text-white-2: rgba(34, 39, 43, 1);
$text-white-50: rgba(255, 255, 255, 0.5);
$text-white-55: rgba(255, 255, 255, 0.55);

//------Buttoncolors-start-------//
$primary-btn-100: rgba(47, 160, 160, 1);
$primary-btn-50: rgba(47, 160, 160, 0.5);
$primary-btn-75: rgba(47, 160, 160, 0.75);



//------Buttoncolors-end-------//

/* Font-Families-start */
$primary-font: "Open Sans", sans-serif;
$secondary-font: "Roboto", sans-serif;
/* Font-Families-End */

/* Font-LineHeights-Start */
$basic-lineheight: normal;
$LH-20: 1.25rem;
$LH-22: 22px;
$LH-26: 26px;
$LH-28: 28px;
$LH-30: 1.875rem;
$LH-35: 35px;
$LH-50: 50px;
$LH-52: 52px;
/* Font-LineHeights-End */

/* Typography-Variables-Start */

/* Heading-Styles-Start */

// $heading-font-weight:bold;
// $h1-font-size:1.25rem;  /*20px*/
// $h2-font-size:1.125rem; /*18px*/
// $h3-font-size:1rem;     /*16px*/
// $h4-font-size:0.875rem; /*14px*/
// $h5-font-size:0.75rem;  /*12px*/
// $h6-font-size:0.688rem; /*11px*/
// $FS-22x:1.375rem;
// $FS-24x:1.5rem;

$heading-font-weight: bold;
$FS-12: 0.75rem;
$FS-13: 0.813rem;
$FS-14: 0.875rem;
$FS-15: 0.938rem;
$FS-16: 1rem;
$FS-18: 1.125rem;
$FS-20: 1.25rem;
$FS-22: 1.375rem;
$FS-24: 1.5rem;
$FS-25: 1.563rem;
$FS-26: 1.625rem;
$FS-28: 1.75rem;
$FS-30: 1.875rem;
$FS-32: 2rem;
$FS-34: 2.125rem;
$FS-36: 2.25rem;
$FS-37: 2.313rem;
$FS-38: 2.375rem;
$FS-40: 2.5rem;
$FS-42: 2.625rem;
$FS-43: 2.688rem;
$FS-55: 3.438rem;
$FS-100: 6.25rem;
/* Heading-Styles-End */

/* font-weights-Start */
$font-weight-normal: normal;
$font-weight-bold: bold;
$FW-3: 300;
$FW-4: 400;
$FW-5: 500;
$FW-6: 600;
$FW-7: 700;
$FW-8: 800;
$FW-9: 900;
/* font-weights-End */

/* Texttransforms-start */
$lowercase: lowercase;
$uppercase: uppercase;
$capitalize: capitalize;
/* Texttransforms-end */

/* border-radius-start */
$BR-4: 4px;
$BR-18: 18px;
$BR-50p: 50%;
/* border-radius-end */