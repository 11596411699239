@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
// font-family: 'Inter', sans-serif;
// font-family: 'Poppins', sans-serif;
// font-family: 'Roboto', sans-serif;

@import "./mixins";
@import "./reset";
@import "./typography";
@import "./variables";

// global-start
.d_aic_jcc {
  @include alignmentctrls(flex, center, center, 0, row);
}

.d_aic_jcs {
  @include alignmentctrls(flex, center, flex-start, 8px, row);
}

.d_aic_jce {
  @include alignmentctrls(flex, center, flex-start, 8px, row);
  @include alignmentctrls(flex, center, flex-end, 0, row);
}

.d_aic_jcb {
  @include alignmentctrls(flex, center, space-between, 0, row);
}

svg {
  color: $text-white;
}

section {
  // min-height: 100vh;
  position: relative;
  padding: 60px 0;
}

// pagination-start//
.pagination {
  gap: 5px !important;

  .page-item.active .page-link {
    background: $primary-btn-100 !important;
  }

  .page-link {
    background: $card-color !important;
    color: $text-white !important;
    border: 1px solid #2d3339 !important;
    border-radius: $BR-4 !important;
    height: 42px;
    width: 42px;
    display: grid;
    place-items: center;

    &:focus {
      box-shadow: none;
    }
  }
}

.badge {
  border-radius: 2px !important;
  padding: 8px 14px !important;
  background-color: $badge-color !important;

  h6 {
    color: $badge-font-color !important;
    font-size: $FS-12 !important;
    font-weight: $FW-3;
  }
}

ul {
  padding: 0 !important;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    list-style: none;
  }
}

.card {
  padding: 1rem;
  background-color: $neutralBlack-2 !important;
}

img {
  max-width: 100%;
  height: auto;
}

form {
  .form-label {
    color: $text-white;
    font-size: $FS-16;
    margin-bottom: 3px;
  }

  .form-check-label {
    color: $text-white;
    font-size: $FS-13;
  }

  .form-control {
    background: $card-color;
    border: 1px solid rgba(48, 57, 64, 1);
    caret-color: $text-white;
    color: $text-white !important;
    border-radius: 2px;

    &:focus {
      border: 1px solid rgba(47, 160, 160, 1);
      box-shadow: none;
      background: $card-color;
    }

    &::placeholder {
      color: $text-white-25;
      font-size: $FS-12;
    }
  }

  .form-icon {
    position: absolute;
    inset: 65% 0 0 2%;
    color: $text-white-25;
  }
}

.primary-btn,
.secondary-btn {
  all: unset;
  color: $text-white;
  text-transform: uppercase;
  background-color: rgb(47, 160, 160);
  border-radius: 4px;
  font-size: $FS-14;
  font-weight: $FW-4;
  padding: 15px 25px;
  letter-spacing: 1px;
  &:disabled{
    background-color: rgb(75 92 92);
    color:rgb(153 153 153);
  }
}

.secondary-btn {
  border-radius: $BR-4 $BR-18 $BR-4 $BR-18;
}

.btn-primary {
  // width: calc(100% - 130px);
  border-radius: 2px !important;
  background: linear-gradient(90.57deg,
      #2fa0a0 9.91%,
      #2fa0a0 53.29%,
      #1b526c 91.56%);
  border: none !important;
  padding: 8px 20px !important;
}

// Formimage - start
.formcircle {
  max-width: 80%;
}

.background-image {
  max-width: 375px;
  position: absolute;
  inset: 13% 0 36% 0;
}

// Formimage - end

// Header-component-start
// .header_container {
//   max-width: 1436px;
// }
nav {
  border-bottom: 1px solid rgba(61, 61, 61, 1);
  flex-wrap: nowrap !important;
  justify-content: flex-start !important;

  .logo {
    max-width: 200px;
  }

  .navbar-nav {
    gap: 21px;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;

    .nav-link {
      color: $text-white;
      font-size: $FS-16;
      font-weight: $FW-3;
      font-family: "Inter", sans-serif;
    }

    .nav-link.active,
    .nav-link:hover {
      position: relative;
      color: $primary-btn-100;

      &:after {
        content: "";
        position: absolute;
        width: 50%;
        height: 3px;
        background-color: rgb(47, 160, 160);
        inset: auto 0 -22px 20%;
        border-radius: 20px;
      }
    }
  }

  .signout {
    .profile_avatar {
      height: 48px;
      width: 48px;
      border-radius: $BR-50p;
      background-color: $text-white;
      display: grid;
      place-items: center;

      span {
        font-weight: $FW-6;
        font-size: $FS-12;
        letter-spacing: 1px;
        font-family: "Inter", sans-serif;
        color: $body-background;

        .profilepic {
          border-radius: $BR-50p;
          height: 48px;
          width: 48px;
          aspect-ratio: 3/1;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    h3 {
      font-size: $FS-14 !important;
      line-height: normal;
      font-weight: $FW-4;
      letter-spacing: 1px;
      color: rgb(255, 255, 255) !important;
      font-family: "Inter", sans-serif;
    }
  }

  .signin_parent {
    min-width: fit-content;
  }

  .signin {
    all: unset;
    min-width: 68px;
    text-align: center;
    border: 1px solid #28a9e0;
    color: rgb(255, 255, 255);
    border-radius: 25px;
    padding: 10px 26px !important;
    text-decoration: none;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: $FS-18;
    @extend .d_aic_jcc;
  }

  .navbar-collapse {
    display: flex !important;
  }
}

// Header-component-end

// new--header-start

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

// #header {
//   transition: all 0.5s;
//   z-index: 997;
//   padding: 15px 0;

//   &.header-scrolled,
//   &.header-inner-pages {
//     background: rgba(40, 58, 90, 0.9);
//   }

//   .logo {
//     font-size: 30px;
//     margin: 0;
//     padding: 0;
//     line-height: 1;
//     font-weight: 500;
//     letter-spacing: 2px;
//     text-transform: uppercase;

//     a {
//       color: #fff;
//     }

//     img {
//       max-height: 40px;
//     }
//   }
// }

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/**
 * Desktop Navigation
 */
// .navbar {
//   padding: 0;

//   ul {
//     margin: 0;
//     padding: 0;
//     display: flex;
//     list-style: none;
//     align-items: center;

//     li {
//       position: relative;

// a,
// a:focus {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px 0 10px 30px;
//   font-size: 15px;
//   font-weight: 500;
//   color: red;
//   white-space: nowrap;
//   transition: 0.3s;

//   i,
//   &:focus i {
//     font-size: 12px;
//     line-height: 0;
//     margin-left: 5px;
//   }
// }

//   a:hover,
//   .active,
//   .active:focus,
//   li:hover > a {
//     color: #47b2e4;
//   }
// }

// .getstarted,
// .getstarted:focus {
//   padding: 8px 20px;
//   margin-left: 30px;
//   border-radius: 50px;
//   color: #fff;
//   font-size: 14px;
//   border: 2px solid #47b2e4;
//   font-weight: 600;

//   &:hover,
//   &:focus:hover {
//     color: #fff;
//     background: #31a9e1;
//   }
// }

// .dropdown {
//   ul {
//     display: block;
//     position: absolute;
//     left: 14px;
//     top: calc(100% + 30px);
//     margin: 0;
//     padding: 10px 0;
//     z-index: 99;
//     opacity: 0;
//     visibility: hidden;
//     background: #fff;
//     box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
//     transition: 0.3s;
//     border-radius: 4px;

//     li {
//       min-width: 200px;

//       a {
//         padding: 10px 20px;
//         font-size: 14px;
//         text-transform: none;
//         font-weight: 500;
//         color: #0c3c53;

//         i {
//           font-size: 12px;
//         }

//         &:hover,
//         .active:hover,
//         li:hover > a {
//           color: #47b2e4;
//         }
//       }
//     }
//   }

//   &:hover > ul {
//     opacity: 1;
//     top: 100%;
//     visibility: visible;
//   }

//   .dropdown {
//     ul {
//       top: 0;
//       left: calc(100% - 30px);
//       visibility: hidden;

//       &:hover > ul {
//         opacity: 1;
//         top: 0;
//         left: 100%;
//         visibility: visible;
//       }
//     }
//   }

//   @media (max-width: 1366px) {
//     .dropdown .dropdown ul {
//       left: -90%;
//     }

//     .dropdown .dropdown:hover > ul {
//       left: -100%;
//     }
//   }
// }
//   }
// }

/**
 * Mobile Navigation
 */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .navbar-mobile .signout {
    bottom: 47%;
  }

  .nav-pills {
    flex-direction: row !important;
    justify-content: space-between;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .header_container {
    max-width: 920px;
  }

  .navbar {

    .navbar-nav .nav-link.active:after,
    .navbar-nav .nav-link:hover:after {
      position: static;
      background-color: transparent;
    }

    .bars_responsive {
      // width: 100%;
      display: flex !important;
      justify-content: end;
    }

    // .navbar-collapse{
    //   display: none !important;
    // }
    .dropdown-toggle {
      justify-content: end;
    }

    .profile-menu {
      display: block !important;
    }

    .navbar-nav {
      display: none;
    }

    .signin,
    .signout {
      display: flex;
    }

    .log {
      display: block;
    }
  }

  .contact_page {
    .card {
      left: 0;

      form {
        padding: 0;
      }
    }
  }

  .border-right {
    border-right: none !important;
  }

  .profile-second-section {
    nav {
      flex-direction: row !important;
    }
  }

  .profile-second-section .nav .nav-link {
    padding: 13px 25px !important;
  }
}

@media (max-width: 1200px) {
  .contact_page {
    .card {
      left: 0;

      form {
        padding: 0 !important;
      }
    }
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;

  .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .nav-tabbs {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 30px !important;
    border-radius: 10px;
    background-color: $body-background;
    overflow-y: auto;
    transition: 0.3s;
  }

  .signin_parent {
    display: block;
  }

  .signin {
    display: flex;
    position: absolute;
    right: 36px;
    left: 40px;
    top: auto;
    bottom: 38px;
    bottom: 47%;
    width: fit-content;
    justify-content: center;
    padding: 0;
  }

  .signout {
    display: none;
  }
}

// new--header-end

// Footer-start
footer {
  border-top: 1px solid rgba(61, 61, 61, 1);

  ul {
    display: flex;
    list-style: none;
    align-items: center;
    font-family: "Inter", sans-serif;

    li {
      position: relative;

      a,
      .powered,
      a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 10px 30px;
        white-space: nowrap;
        transition: 0.3s;
        text-decoration: none;
        font-weight: $FW-3;
        font-size: $FS-16;
        color: $text-white-25;

        i,
        &:focus i {
          font-size: $FS-12;
          line-height: 0;
          margin-left: 5px;
        }

        &:hover,
        &.active,
        &.active:focus,
        li:hover>a {
          color: $text-white-25;
        }
      }
    }

    h6 {
      padding-bottom: 1px;

      font-size: $FS-14;
      font-weight: $FW-4;
    }
  }

  .social-media {
    color: $text-white;
    font-size: $FS-20;
  }
}

// Footer-end

// sigin-page-start
.sign-in {
  .text {
    // @include font-properties(38px, 46px, 300, $text-white, normal);
    font-size: 38px;
    line-height: 46px;
    font-weight: 300;
    color: $text-white;
  }

  h2 {
    font-weight: $FW-4;
  }

  a {
    text-decoration: none;
    color: $text-white;
    font-size: $FS-14;
  }

  .sign {
    font-size: $FS-12;
  }

  .or {
    position: relative;
    color: $text-white;
    background-color: $text-white-2;
    border-radius: $BR-50p;
    height: 32px;
    width: 32px;
    display: grid;
    place-items: center;

    &:after,
    &::before {
      position: absolute;
      width: 100%;
      content: "";
      background-color: $text-white-2;
      height: 2px;
    }

    &:after {
      top: 15px;
      right: 32px;
      width: 250px;
    }

    &::before {
      top: 15px;
      left: 32px;
      width: 250px;
    }

    .form-control {
      padding: 0.3rem 2rem;
      color: white;
    }

    form {
      h3 {
        span {
          color: $text-white-25;
        }
      }
    }
  }

  .socialmedia {
    background-color: $text-white-2;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 25px;
    display: grid;
    place-items: center;

    .google-icon {
      width: 17px;
      height: 17px;
    }
  }

  h3 {
    font-weight: $FW-6;
    font-size: $FS-12 !important;

    span {
      color: $text-white-25;
      font-weight: $FW-4;
    }
  }
}

// sigin-page-end

// forgetpassword-start
.passwordform-height {
  min-height: 84vh;
}

// forgetpassword-start

// ----Home-page-start----//
.homepage {
  min-height: 100vh;
  @include alignmentctrls(flex, center, center, 12px, column);

  .container,
  .container-fluid {
    position: relative;
    z-index: 1;
  }

  .overlay {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(32 32 35 / 76%);
    position: absolute;
    z-index: 0;
  }

  h3 {
    font-size: $FS-26;
    font-weight: $FW-4;
    text-align: center;
    line-height: $LH-35;

    span {
      color: $primary-btn-100;
      font-weight: $FW-7;
    }
  }

  h1 {
    font-weight: $FW-4;
    font-size: $FS-55;
    text-align: center;
    text-transform: $uppercase;
  }

  p {
    color: $text-white-50;
    font-size: $FS-12;
    text-align: center;
  }
}

.homepage_second_section {
  h1 {
    font-size: $FS-26;
    line-height: $LH-35;
    text-transform: none;
  }
}

// ----Home-page-end----//

// -----company-page-start-----//
.company_first_section {
  min-height: 473px;

  .overlay {
    background-color: rgb(2 2 2 / 30%);
    min-height: 473px;

    h1 {
      // font-family: "Poppins", sans-serif;
      font-weight: $FW-6;
      font-size: $FS-38;
      text-transform: uppercase;
      text-align: center;
      padding-top: 30px;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-size: $FS-14;
      color: $text-white-50;
    }
  }
}

.company_second_section {
  h2 {
    font-size: $FS-16;
    line-height: $LH-28;
    color: rgba(255, 255, 255, 0.6);
    font-weight: $FW-3;
    margin: 0;

    strong {
      color: $primary-btn-100;
    }
  }

  .icon {
    height: 35px;
    width: 35px;

    svg {
      width: 100%;
    }
  }

  .elephant_img {
    width: 90%;
  }
}

.company_fourth_section {
  p {
    color: $text-white-90;
    line-height: $LH-26;
    font-weight: $FW-4;
  }

  .card {
    padding: 25px;
    background-color: rgba(42, 48, 53, 1);


    .icon {
      height: 50px;
      width: 50px;
      display: grid;
      place-items: center;

      svg {
        color: $text-white;
      }
    }

    h3 {
      font-size: $FS-18;
      font-weight: $FW-5;
      color: $text-white-90;
      padding-top: 12px;
    }

    &.active {
      background-color: $primary-btn-75 !important;

      svg {
        stroke: $text-white;
      }
    }

    &.activeglobe {
      background-color: $primary-btn-50 !important;

      svg {
        stroke: none;
      }

      strong {
        color: $text-white-90;
      }
    }

    p {
      font-size: $FS-16;
      font-weight: $FW-4;
      color: $text-white-50;
      line-height: $LH-22;
      padding-top: 0;
    }
  }

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.company_fifth_section {
  min-height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .join_us {
    padding: 62px 68px 68px 112px;
    background-color: rgba(34, 39, 43, 0.94) !important;
  }

  h3 {
    font-weight: $FW-7;
    font-size: $FS-26 !important;
    color: $primary-btn-100;
  }

  h2 {
    font-weight: $FW-8;
    line-height: $LH-50;
    padding: 18px 0 15px 0;
  }

  p {
    font-weight: $FW-5;
    font-size: $FS-16;
    line-height: $LH-28;
    color: $text-white-90;
  }
}

.team_founders {
  h2 span::before {
    inset: 44% 0 0 25%;
  }

  .team_para {
    padding: 0 25%;
  }

  .card {
    overflow: hidden;
    height: 367px;

    .content,
    .content1 {
      transform: translateY(100%);
      transition: all 0.35s ease;
      background: rgba(0, 0, 0, 0.582);
      width: 100%;
    }

    .content1 {
      transform: translatex(100%);
      height: 100px;
    }

    &:hover .content1 {
      transform: translatex(-25px);
    }

    &:hover .content {
      transform: translateY(-25px);
    }
  }

  .avatar1,
  .avatar {
    border-radius: $BR-4;
    overflow: hidden;
    background: black;
    aspect-ratio: 3/1;
    height: 380px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .avatar {
    .hover_img {
      justify-content: flex-end !important;
    }
  }

  .avatar,
  .avatar1 {
    position: relative;
    overflow: hidden;
    width: 100%;

    .hover_img {
      background: rgb(47, 160, 160);
      background: linear-gradient(0deg,
          rgba(47, 160, 160, 0.75) 50%,
          rgba(47, 160, 160, 0.1) 100%);
      width: 58px;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      right: -100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 2rem;
      transition: all 0.5s ease-in-out;
      text-wrap: nowrap;
      p {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        margin-top: 15px;
        letter-spacing: 3px;
      }

      .social {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 0.51rem;

        a {
          transition: ease-in-out 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          width: 32px;
          height: 32px;
          background: #eff2f8;

          svg {
            color: rgb(144, 138, 138);
            font-size: $FS-16;
            margin: 0 2px;
          }

          a+a {
            margin-left: 8px;
          }
        }
      }
    }

    &:hover .hover_img {
      right: 0;
    }
  }

  .content {
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgb(0 0 0) 0%, rgb(10 10 10 / 0%) 100%);
  }

  .avatar1:hover .content {
    transform: translateY(-67px);
  }

  .title {
    margin: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    font-size: $FS-22;
    font-weight: $FW-8;
    color: $text-white !important;
    // font-family: "Poppins", sans-serif;
  }

  .avatar1:hover .title {
    transform: translateX(0);
  }

  .red {
    width: 200px;
  }

  .member_name {
    font-weight: $FW-5;
    font-size: $FS-24;
    margin-bottom: 3px;
  }

  .member_profession {
    color: $text-white-55;
    font-weight: $FW-3;
    font-size: $FS-16;
  }
}

// Section Haders

.section-header {
  position: relative;
  text-align: left;

  h1 {
    display: flex;
    flex-direction: column;
    font-size: $FS-37;
    font-weight: $FW-8;
    color: $text-white;
    gap: 10px;

    span {
      font-size: $FS-15;
      color: $primary-btn-100;
      position: relative;
      font-weight: $FW-7;

      &::before {
        content: "";
        width: 32px;
        height: 3px;
        display: inline-block;
        background: $primary-btn-100;
        margin: 4px 10px 4px 0;
      }
    }
  }

  h5 {
    font-size: $FS-20;
    line-height: $LH-30;
    color: $text-white-90;
    // font-family: "Poppins", sans-serif;
    font-weight: $FW-5;
    padding-top: 18px;

    b {
      font-weight: $FW-7;
    }
  }
}

// -----company-page-end-----//
// ----productpage-start----//
.products {
  .title {
    font-weight: $FW-4;
    font-size: $FS-40;
    list-style: none;
    line-height: $LH-52;

    b {
      font-size: $FS-43;
      text-decoration: underline;
      color: $primary-btn-100;
    }
  }
}

.products_second_section {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.054) 100%),
    linear-gradient(0deg, #1b1e1f, #1b1e1f);

  padding: 5rem 0;

  .card {
    background: linear-gradient(66.24deg, #1e2225 0%, rgba(30, 34, 37, 0) 135%);
    padding: 50px;
    position: relative;

    &:hover {
      background: rgba(30, 34, 37, 1);
    }

    &:hover .icon {
      background-color: $primary-btn-100;
    }

    .icon {
      height: 72px;
      background-color: $neutralBlack-10;
      width: 72px;
      border-radius: $BR-50p;
      display: grid;
      place-items: center;
      padding: 12px;
    }

    h1 {
      font-weight: $FW-4;
      font-size: $FS-28;
      padding-top: 30px !important;
    }

    p {
      font-size: $FS-16 !important;
      font-weight: $FW-4;
      line-height: $LH-26;
    }
  }

  .number {
    position: absolute;
    inset: auto 4% 3% auto;
    font-size: $FS-100;
    color: $neutralBlack-10 !important;
    font-weight: $FW-8;
  }
}

// ----productpage-end----//
// ----pricing-start-----//
.pricing {
  min-height: calc(100vh - 120px);

  h1 {
    font-size: $FS-34;
    font-weight: $FW-4;
    text-transform: uppercase;
  }

  .card {
    position: relative;

    &.active {
      box-shadow: rgb(107 211 211) 0px 0px 8px 1px;
    }

    .ribbon {
      position: absolute;
      right: -5px;
      top: -5px;
      z-index: 1;
      overflow: hidden;
      width: 75px;
      height: 75px;
      text-align: right;

      span {
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        line-height: 20px;
        transform: rotate(45deg);
        width: 100px;
        display: block;
        background: $primary-btn-100;
        // background: linear-gradient(#57ee29 0%, #65f740 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px;
        right: -21px;

        &::before {
          content: '';
          position: absolute;
          left: 0px;
          top: 100%;
          z-index: -1;
          border-left: 3px solid $primary-btn-75;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-top: 3px solid $primary-btn-75;
        }

        &::after {
          content: '';
          position: absolute;
          right: 0%;
          top: 100%;
          z-index: -1;
          border-right: 3px solid $primary-btn-75;
          border-left: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-top: 3px solid $primary-btn-75;
        }
      }

    }

    .card-header {
      padding: 20px 0 60px 0;
      border-bottom: none;
      background-color: $card-header;

      &.active {
        background: linear-gradient(66.24deg, #333a3f 0%, rgba(30, 34, 37, 0) 135%);
      }

      h2 {
        font-size: $FS-22;
        font-weight: $FW-9;
        color: $text-white;
        text-transform: uppercase;

      }
    }

    .card-icon {
      color: $text-white;
      border-radius: $BR-50p;
      background-color: $neutralBlack-10;
      text-align: center;
      height: 62px;
      width: 62px;
      margin: -45px auto 0 auto;
      place-items: center;
      display: grid;

      svg {
        font-size: $FS-25;
      }
    }

    .card-body {
      background-color: rgba(36, 42, 46, 1);

      &.active {
        background: $card-header;
      }

      h2 {
        font-size: $FS-22;
        font-weight: $FW-4;
      }

      ul {
        width: 100%;

        li {
          border-bottom: 1px dashed rgba(226, 208, 208, 0.25);
          color: $text-white;
          font-size: $FS-14;
          padding: 10px 0;
          text-align: center;
          list-style: none;
          font-weight: $FW-4;
          width: 100%;
          letter-spacing: 0.5px;
        }
      }

      button {
        background: rgb(47, 160, 160);
      }
    }
  }

  .button-box {
    margin: auto;
    position: relative;
    background: $transparent;

    #btn {
      left: 0;
      top: 0;
      position: absolute;
      width: 110px;
      height: 100%;
      background: $primary-btn-100;
      border-radius: 30px;
      transition: .5s;
    }

    .toggle-btn {
      padding: 10px 40px;
      color: $text-white;
      cursor: pointer;
      background: $transparent;
      border: none;
      outline: none;
      position: relative;
      text-align: center;
      font-family: 'Poppins', sans-serif;
    }
  }
}

// ----pricing-end-----//

// ---contactus-page-start---//
.contact_page {
  h1 {
    font-weight: $FW-6;
    font-size: $FS-36;
  }

  h2 {
    font-size: $FS-32;
  }

  .icon {
    height: 25px;
    width: 25px;
    margin-right: 6px;
    display: grid;
    place-items: center;

    svg {
      height: 100%;
      color: $text-white-50;

    }
  }

  form {
    padding-right: 60px !important;
  }

  .card {
    padding: 54px;
    position: relative;
    background: $card-color !important;

    .form-control {
      border: none;
      border-bottom: 1px solid $text-white-25;
      border-radius: 0;
      font-size: $FS-18;
      padding-left: 0;
      padding-right: 0;

      &:focus {
        border-bottom: 2px solid $text-white;
      }

      &::placeholder {
        font-size: $FS-16;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .form-label {
      margin-top: 15px;
      font-size: $FS-14;
      font-weight: $FW-3;
      text-transform: capitalize;
      color: $text-white-50;
      display: block;

      &:focus-within {
        color: $text-white;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    gap: 0px;

    a {
      font-size: $FS-14;
      font-weight: $FW-4;
      color: $text-white-90;
      text-decoration: none;
      line-height: 18px;
    }
  }
}

// ---contactus-page-end---//
// blogpage-start//
.blogpage_first_section {
  li {
    margin-top: 6px;

    a {
      font-family: "Roboto", sans-serif;
      font-size: $FS-16;
      text-decoration: none;
      color: $text-white;

      &.active {
        border-bottom: 1px solid $text-white-75;
      }
    }

    &:last-child {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        inset: 30% 0 0 -54%;
        height: 8px;
        width: 8px;
        background-color: $primary-btn-100;
      }
    }
  }
}

.blog {
  .blog-res {

    // -webkit-column-count:4;
    // -moz-column-count:4;
    a {
      text-decoration: none;
      cursor: auto;
    }

    // column-count: 2;
    // padding: 25px;
    // -webkit-column-gap: 20px;
    // -moz-column-gap: 20px;
    // column-gap: 20px;
    .card {
      width: 100% !important;
      height: auto;
      margin: 4% auto;
      cursor: pointer;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }
  }

  .card {
    position: relative;
    background-color: $card-color;
    border-radius: 8px;
    overflow: hidden;

    .card-img {
      background-color: $neutralBlack-100;
      width: 100%;
      overflow: hidden;
      position: relative;
      aspect-ratio: 3/2;
      border-radius: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-container {
      position: relative;
      padding: 20px 30px;

      button {
        all: unset;
        position: absolute;
        height: 32px;
        width: 32px;
        border-radius: $BR-50p;
        background-color: $neutralBlack-20;
        box-shadow: 0px 5px 4px 0px #00000040;
        color: $text-white;
        display: grid;
        place-items: center;
        inset: -15px 5% 0 auto;
      }

      h3 {
        font-size: $FS-24;
        line-height: $LH-28;
        font-weight: $FW-5;
        color: $text-white;
      }

      .card_footer_image {
        height: 32px;
        width: 32px;
        overflow: hidden;
        position: relative;
        aspect-ratio: 3/2;
        border-radius: $BR-50p;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .posts-card-img {
        height: 86px;
        width: 86px;
        overflow: hidden;
        position: relative;
        aspect-ratio: 1/1;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .card_footer_title {
      h5 {
        font-weight: $FW-5;
        font-size: $FS-14 !important;
        line-height: $LH-20;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h6 {
        font-size: $FS-12;
        color: $text-white-25;
        font-weight: $FW-4;
      }
    }

    &:hover .card-container button {
      background-color: $primary-btn-100;
    }
  }

  form {
    position: relative;

    .form-control {
      padding: 15px 15px;
      border-radius: 0;
      border: 1px solid $form-border;
      background: linear-gradient(0deg, #1f2224, #1f2224),
        linear-gradient(0deg, #25292c, #25292c);
    }

    svg {
      color: $text-white;
      position: absolute;
      inset: 34% auto 0 92%;
      font-size: $FS-20;
    }
  }

  .posts-card {
    background: $card-color;
    padding: 32px;

    h3 {
      margin-bottom: 24px;
      position: relative;
      display: block;

      &::after {
        position: absolute;
        content: "";
        height: 1px;
        width: 72px;
        background-color: $text-white-75;
        inset: auto 0 -20% 1%;
      }
    }

    .recent {
      font-size: $FS-15 !important;
      line-height: $LH-22;
    }

    .recent-content {
      font-size: $FS-15 !important;
      // padding-top: 5px;
      margin: 0;
      font-weight: $FW-4;
    }
  }
}

.react-share__ShareButton {
  position: relative !important;
  height: none !important;
  width: none !important;
  border-radius: $BR-50p;
  background-color: $neutralBlack-20;
  box-shadow: none !important;
  color: $text-white;
  display: grid;
  place-items: center;
  inset: 0 !important;
}

// blogpage-end//

// blogspage-1 start//
.blogspage_second_section {
  .card {
    background-color: $blogpagecard !important;
    padding: 32px;

    .blogsaiimage {
      height: 562px;
      overflow: hidden;
      position: relative;
      aspect-ratio: 3/2;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    p {
      font-size: $FS-14;
      font-weight: $FW-4;
      color: $text-white-50;
      line-height: $LH-20;
    }

    .card-body {
      padding: 32px;

      h3 {
        font-size: $FS-20;
      }

      p {
        font-size: $FS-15;
        margin: 0;
        padding: 5px 0;
      }

      blockquote {
        position: relative;
        font-size: $FS-20;
        padding: 48px 35px;
        color: $text-white-75;
        line-height: $LH-30;
        background-color: rgb(42, 48, 53);
        font-style: italic;
        font-weight: $FW-4;
      }

      blockquote::before {
        position: absolute;
        content: "’";
        color: $card-color;
        font: 500 15em/2em sans-serif;
        top: -4rem;
        left: unset;
        right: 0.15em;
      }

      blockquote::after {
        position: absolute;
        content: "’";
        color: $card-color;
        font: 500 15em/2em sans-serif;
        top: -4rem;
        left: unset;
        right: 0;
      }

      .blog-footer {
        background-color: $body-background;
        padding: 1.5rem;

        li {
          p {
            font-size: $FS-12;
          }

          svg {
            font-size: $FS-18;
            color: $text-white-50;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .badge-parent {
    position: absolute;
    inset: auto 0 12px 12px;
  }
}

// blogspage-1 end//

// @media only screen and (max-width: 992px) {
//   .contact {
//     .card {
//       left: 0;
//       position: relative;
//       margin-top: 0;
//     }
//   }
//   nav .nav-item .nav-link.active:after {
//     inset: 42% 100% -46% auto;
//     width: 2%;
//   }
// }

// @media screen and (max-device-width: 1600px) {
//   .homepage {
//     h3 {
//       font-size: clamp(14px, 3vw, 35px) !important;
//     }
//     h1 {
//       font-size: clamp(18px, 3vw, 2.5rem) !important;
//     }
//     p {
//       font-size: clamp(12px, 1vw, 16px);
//     }
//   }
// }
// .compantabout1 {
//   h2 {
//     font-size: clamp(20px, 3vw, 35px) !important;
//   }
// }

@media only screen and (max-width: 600px) {
  section {
    padding: 20px 0;
  }

  nav {
    .logo {
      max-width: 210px;
    }

    .nav-link.nav-link.active {
      position: relative;

      // &::before {
      //   content: "";
      //   width: 20px;
      //   height: 3px;
      //   display: inline-block;
      //   background: rgb(47, 160, 160);
      //   margin: 4px 10px 4px 0;
      // }
      &::after {
        position: static;
        background-color: transparent;
      }
    }

    .signin_parent {
      display: none;
    }
  }

  .home_firstsection {
    min-height: 100vh;
  }

  .homepage {
    min-height: 30vh;

    h1 {
      font-size: $FS-36;
    }

    .overlay {
      min-height: 100%;
    }
  }

  .companyabout1 {
    h5 {
      font-size: 0.9rem !important;
      line-height: $LH-22;
    }

    p {
      font-size: $FS-12 !important;
      line-height: $LH-20;
    }
  }

  .company_second_section {
    p {
      font-size: $FS-12;
      line-height: $LH-20;
    }
  }

  .company_fourth_section .card p {
    font-size: $FS-12;
  }

  .company_fifth_section {
    h2 {
      line-height: $LH-26;
    }

    .join_us {
      padding: 20px;
    }
  }

  .team_founders {

    .avatar,
    .avatar1 {
      aspect-ratio: auto;
      width: 294px;
      margin: auto;
    }

    .member_name,
    .member_profession {
      padding-left: 38px;
    }

    .team_para {
      padding: 0;
    }

    .avatar1:hover .content {
      transform: translateY(-60px);
    }
  }

  .pricing {
    h1 {
      font-size: $FS-30 !important;
    }

    .card {
      .card-header {
        h2 {
          font-size: $FS-25 !important;
        }
      }

      .card-body {
        h2 {
          font-size: $FS-24 !important;
        }

        li {
          font-size: $FS-12 !important;
        }
      }
    }
  }

  .products {
    .title {
      font-size: $FS-20;
      line-height: $LH-26;

      b {
        font-size: $FS-20;
      }
    }
  }

  .products_second_section {
    padding: 1rem 0;

    .card {
      padding: 20px;

      .icon {
        height: 55px;
        width: 55px;
      }

      h2 {
        font-size: $FS-20;
      }

      p {
        line-height: $LH-22;
        font-size: $FS-12 !important;
      }
    }

    .number {
      font-size: $FS-36;
    }
  }

  // .blog .card .card-container h3 {
  //   font-size: 14px;
  //   line-height: 20px;
  // }

  .contact_page {
    .card {
      left: 0;
      padding: 30px;

      form {
        padding: 0 !important;
      }
    }
  }

  .blogspage_second_section {
    .card {
      .card-body {
        p {
          font-size: $FS-12;
        }

        blockquote {
          font-size: $FS-14;

          &::after,
          &::before {
            top: 3rem;
          }
        }
      }
    }
  }

  .section-header {
    h1 {
      font-size: 20px;
    }

    h5 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.id {
  border: 1px dashed lightcoral;
}

//css loader
.cssloader-container {
  min-height: calc(100vh - 100px);
  display: grid;
  place-items: center;

  /** loader **/
  .sh1 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: $text-white-75 transparent transparent transparent;
    margin: 0 auto;
    animation: shk1 1s ease-in-out infinite normal;
    opacity: 0.75;
  }

  .sh2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 50px 50px;
    border-color: transparent transparent $primary-btn-100 transparent;
    margin: -50px auto 0;
    animation: shk2 1s ease-in-out infinite alternate;
    opacity: 0.75;
  }
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }

  100% {}
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }

  100% {}
}

.lt {
  font-size: $FS-14;
  color: $text-white-75;
  margin: 30px auto;
  text-align: center;
  font-weight: $FW-4;
  letter-spacing: 2px;
}

.active {
  p {
    strong {
      color: $text-white-90;
    }
  }

}


.profile-menu {
  flex-basis: auto;

  .dropdown-menu {
    right: 0;
    left: unset;

    li {
      font-family: 'Inter', sans-serif;
      font-weight: $FW-5;
      font-size: $FS-16;
      line-height: $LH-20;
      color: $neutralBlack-100;
    }

    .plan-dropdown-field {
      padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);

      h6 {
        font-family: 'Inter', sans-serif;
        font-weight: $FW-5;
        font-size: $FS-15;
        color: $neutralBlack-100;
      }

      p {
        font-size: $FS-14;
        font-weight: $FW-4;
        color: $secondary-text-color;
      }

      .badge {
        background-color: rgba(255, 237, 213, 1) !important;
        color: rgba(251, 171, 109, 1);
        font-size: $FS-13;
      }
    }

    .profile_avatar {
      height: 44px;
      width: 44px;
      background-color: $avatarBackground;
      border-radius: $BR-50p;
      -webkit-border-radius: $BR-50p;
      -moz-border-radius: $BR-50p;
      -ms-border-radius: $BR-50p;
      -o-border-radius: $BR-50p;

      .profilepic {
        overflow: hidden;
        height: 44px;
        width: 44px;
        border-radius: $BR-50p;
      }
    }

    .dropdown-profilename {
      width: calc(100% - 56px);
      font-family: 'Inter', sans-serif;
      font-weight: $FW-5 !important;

      h6 {
        font-size: $FS-18;
        line-height: $LH-20;
        color: $neutralBlack-100 !important;
        display: flex;
        flex-direction: column;
        gap: 2px;

        span {
          font-size: $FS-15;
          color: $secondary-text-color;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 190px;
        }
      }


    }
  }

  .fa-fw {
    margin-right: 10px;
  }
}

.toggle-change {
  &::after {
    border-top: 0;
    border-bottom: .3em solid;
  }
}

.dropdown-menu.show {
  position: absolute;
  padding: 1rem !important;
  width: 305px;
  border-radius: 1px;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-collapse {
  flex-basis: auto;
}

.dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px 16px;

  &:hover {
    background-color: $avatarBackground;
  }
}

.modal-content {
  background-color: $card-color;
}

.modal-header {
  border-color: $badge-color;
  color: $text-white-90;
}

.modal-body {
  padding: 2rem;

  h3 {
    font-size: 28px;
    font-weight: 700;
  }

  h6 {
    font-size: 24px;
    font-weight: 500;

    span {
      font-size: 13px;
      font-weight: 400;
    }

    // color:rgb(85, 83, 83);
  }
}

.modal-footer {
  border-color: $badge-color;

  p {
    // color:rgb(167, 165, 165);
    text-align: center;
    width: 100%;
    font-size: $FS-16;
  }
}

.btn-close {
  filter: grayscale(100%) invert(100%) brightness(1000%);
}

.profile_page {
  min-height: 260px;
}

.profile-second-section {
  font-family: 'Poppins', sans-serif;
  color: $text-white-75;

  .nav {
    flex-direction: column;

    .nav-link {
      padding: 16px 24px;
      font-weight: $FW-5;
      font-size: $FS-16;
      line-height: $LH-28;
      color: $text-white-75;
      display: flex;
      align-items: center;
      border-radius: $BR-4;
      margin:1px 0;


      &.active,
      &:hover {
        background-color: $blogpagecard !important;
        color: $primary-btn-100;
      }

      &.active svg,
      &:hover svg {
        color: $primary-btn-100;
      }

    }
  }

  .avatar-img {
    height: 92px;
    width: 92px;
    display: grid;
    place-items: center;
    border-radius: $BR-50p ;
    -webkit-border-radius: $BR-50p ;
    -moz-border-radius: $BR-50p ;
    -ms-border-radius: $BR-50p ;
    -o-border-radius: $BR-50p ;
    background-color: $badge-font-color;
    overflow: hidden;
  }

  .border-left {
    border-left: 1px solid rgba(132, 132, 132, 1);
  }

  h6 {
    color: $text-white-75 !important;
    font-weight: $FW-5;
    font-size: $FS-28;
    line-height: $LH-28;
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-transform: capitalize;

    span {
      font-weight: $FW-4;
      font-size: $FS-15;
      line-height: $LH-28;
    }
  }


  .content {
    width: calc(100% - 128px);
  }

  .linkedin-logo {
    svg {
      color: #28a9e0;
      background-color: $text-white;
      border-radius: $BR-4;
      -webkit-border-radius: $BR-4;
      -moz-border-radius: $BR-4;
      -ms-border-radius: $BR-4;
      -o-border-radius: $BR-4;
    }
  }



  .plans {
    p {
      font-size: $FS-16;
      font-weight: $FW-5;
      line-height: $LH-20;
      color: $text-white-50;
    }

    h6 {
      font-size: $FS-18;
      font-weight: 500;
      line-height: $LH-20;
    }

    .warning,
    .danger {
      background-color: rgba(255, 237, 213, 1) !important;
      color: rgba(251, 171, 109, 1);
      font-family: 'Inter', sans-serif;
      font-size: $FS-14;
      font-weight: $FW-5;
      line-height: $LH-20;
    }

    .danger {
      background: rgba(218, 81, 81, 1) !important;
      color: $text-white;
    }
  }

  .card {
    background-color: $transparent;

    .card-header {
      background-color: $blogpagecard;

      h6 {
        font-size: $FS-24;
        font-weight: $FW-5;
        line-height: $LH-28;
      }

      .btn-outline-secondary {
        font-size: $FS-18 !important;
        font-weight: $FW-4;
        line-height: $LH-22;

        svg {
          color: $text-white-50 !important;
        }
      }

    }

    .card-body {
      background-color: $card-body-color;

      label {
        font-size: 0.85rem;
        font-weight: $FW-4;
        line-height: $LH-20;
        color: rgba(154, 154, 154, 0.71);
        margin-bottom: 5px;
      }

      h5 {
        font-size: $FS-18;
        font-weight: $FW-5;
        line-height: $LH-20;
        color: $text-white-75;
      }
    }
  }
}

.profile-form {
  form {
    padding: 0 !important;
    overflow: hidden;

    .card {
      padding: 20px 30px !important;
    }
  }
}

.min-height-min {
  min-height: 50vh;
}

.billing {
  overflow-y: auto;
  min-height: 400px;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(167, 165, 165);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: $text-white;
  }

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid $text-white-50;

      &:hover {
        background-color: $text-white-2;
      }
    }

    th,
    td {
      font-size: $FS-18;
      padding: 0.6rem 0.6rem;
      white-space: nowrap;
    }

    thead {
      width: 100%;

      tr {
        position: sticky;
        top: 0;
        background-color: #161515;
        z-index: 1;
      }
    }

    tbody {
      max-height: 333px;
      overflow: auto;
      overflow-x: auto;
      width: 100%;
    }

    tbody {

      td {
        font-size: $FS-16;
        color: $text-white-75;
        font-weight: $FW-4;

        span {
          font-weight: $FW-6;
          color: $text-white;
          vertical-align: middle;
        }

        .usd {
          @extend td;
        }

        .badge {
          padding: 3px 6px !important;
          vertical-align: bottom;
        }

        button {
          border: 2px solid $transparent;
          color: $text-white;
        }

        .download-btn {
       
          a {
            color: $text-white-75;
            padding:10px;
            border-radius: 2px;
            span {
              display: none;
            }
          }
          &:hover span{
            display: block;
          }
          &:hover a{
            background-color: $primary-btn-75;
          }
        }

        // .btn-group:hover button {
        //   border: 2px solid $text-white-50;
        // }

        .past-due,
        .uncollectible,
        .paid,
        .open {
          background-color: #fde9ee !important;
          border: 1px solid #fbd6de;
          color: #cd415e;
          border-radius: 12px !important;
        }

        .uncollectible {
          background-color: #ebeef1 !important;
          border: 1px solid #ebeef1;
          color: $neutralBlack-50;
        }

        .paid {
          background-color: #d1fab3 !important;
          border: 1px solid #a8f171;
          color: #217007;
        }

        .open {
          border: 1px solid #a7e7fc;
          background-color: #cbf5fd !important;
          color: #045ad0;
        }
      }

      td:last-child {
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: end;


      }
    }
  }
}

.privacy-policy,
.terms_of_use {
  h2 {
    font-size: $FS-22;
    color: $text-white-90 !important;
  }

  ul {
    list-style-type: circle;
    flex-direction: column;
    align-items: start;
    list-style-position: inside;
    position: relative;
    padding-left: 25px !important;
    position: relative;
    gap: 5px;

    li:before {
      position: absolute;
      content: "•";
      left: 0;
      font-size: 30px;
    }
  }

  // ul,li{
  //   display: block;
  //   list-style: none;
  //   margin: 0;
  //   padding: 0;
  // }

  p,
  li {
    color: $text-white-50 !important;
    font-size: $FS-14;
    line-height: 18px;
  }
}

.id1 {
  border: 2px dashed red;
}

.id2 {
  border: 2px dashed rgb(208, 255, 0);
}

.id3 {
  border: 2px dashed rgb(30, 131, 240);
}

.id4 {
  border: 2px dashed rgb(244, 25, 200);
}

.id5 {
  border: 2px dashed rgb(98, 12, 122);
}

.id6 {
  border: 2px dashed rgb(0, 255, 225);
}

.mh-44 {
  min-height: 44px;
}

.min-height-100 {
  min-height: calc(100vh - 16vh);
}

.robo-bg{
  background-image: url('../public/assets/robot_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  a{
    color: #2FA0A0;
  }
}
.robo-bg.robo-detail-bg{
  background-image: url('../public/assets/mangroup.png');
}
.bottombadge{
  position: relative;
    top: -48px;
}
.searchicon{
  font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
}


.blog .card .card-container h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #FFFFFF;
}
.blogsimg{
  max-height: 400px;
}
.blogtxt{
font-size: 18px;
font-weight: 400;
line-height: 26px;
text-align: left;
color: #FFFFFF;
opacity: 0.5;
a{
  cursor: pointer;

}
}
.blog{
  blockquote{
    font-size: 21px;
    width:100%;
    margin:20px auto;
    color: #FFFFFF;
    padding:1.2em 30px 1.2em 75px;
    line-height:1.6;
    position: relative;
    background:#2A3035;
    font-style: italic;
  }
  
  blockquote::before{
  
  }
  
  blockquote::after{
    height: 76px;
    width: 80px;
    z-index: 99999;
    font-family: Arial;
    content: "";
    background-image: url('../public/assets/quote.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 23px;
    bottom: -8px;
  }
  
  blockquote span{
    display:block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top:1em;
  }
  .socialic{
    color: #FFFFFF;
    opacity: 0.5;
    li{
      padding-left: 10px;
    }
  }
}